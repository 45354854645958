import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Banner } from '@calm-web/design-system';

import { useNavToPlanPageAndOpenUpdatePaymentDetailsModal } from '@/hooks/api/useNavToPlanPageAndOpenUpdatePaymentDetailsModal';
import { usePurchase } from '@/hooks/api/usePurchase';

import messages from './messages';
import { BannerWrapper, WebLink } from './styles';

const BillingAddressBanner: FC = () => {
	const navToPlanPageAndOpenUpdatePaymentDetailsModal = useNavToPlanPageAndOpenUpdatePaymentDetailsModal();
	const { formatMessage } = useIntl();
	const { paymentInfo, paymentError, loadingPaymentInfo } = usePurchase();

	const hasBillingAddress = !!paymentInfo?.billing_details?.address;

	return hasBillingAddress || loadingPaymentInfo || paymentError ? null : (
		<BannerWrapper>
			<Banner backgroundColor="yellow" addMargins data-testid="billing-address-banner">
				{formatMessage(messages.promptForAddress, {
					link: (...chunks: ReactNode[]) => (
						<WebLink data-testid="plan-redirect-link" onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
							{chunks}
						</WebLink>
					),
				})}
			</Banner>
		</BannerWrapper>
	);
};

export default BillingAddressBanner;
