import { FC, FormEvent, useCallback } from 'react';

import { Checkbox, Loader, Text } from '@calm-web/design-system';
import { booleanFromCheckbox } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig, { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth';
import { useHealthConfigForm, useHealthConfigSubmitData } from '@/hooks/forms/useHealthConfigForm';
import { useHealthSurveyConfigForm } from '@/hooks/forms/useHealthSurveyConfigForm';
import { HealthConfig } from '@/types/health';
import { Partner } from '@/types/store/reducers';

import StickySaveButton from '../StickySaveButton';
import { CheckboxDetails, ScreeningCard } from './styles';

interface Props {
	healthConfig: HealthConfig;
	partnerId: string;
}

export function MentalHealthScreeningInner({ healthConfig, partnerId }: Props): ReturnType<FC> {
	const healthConfigForm = useHealthConfigForm(healthConfig);
	const { getHealthConfigSubmitData } = useHealthConfigSubmitData(
		healthConfigForm.baseFormProps,
		healthConfigForm.eligibilityValidatorFormProps,
	);
	const { formProps, hasChangedAny, hasTouchedAny } = useHealthSurveyConfigForm(healthConfig?.survey_configs);
	const [submitExistingHealthConfigForm, { loading }] = useSubmitExistingHealthConfigForm(
		healthConfigForm.resetAllDirtyStates,
	);
	const [hasValidPermissions, actions] = usePermissions();

	const onSubmit = useCallback(
		async (e: FormEvent): Promise<void> => {
			e.preventDefault();
			const healthConfigSubmitData = getHealthConfigSubmitData();

			const newSurveyConfig = [
				{
					survey_type: 'mental_health',
					survey_id: booleanFromCheckbox(formProps.model.show_self_harm_question, 'show_self_harm_question')
						? 'mental_health'
						: 'mental_health_no_self_harm_question',
					all_questions_required: booleanFromCheckbox(
						formProps.model.all_questions_required,
						'all_questions_required',
					),
					show_screening_results: booleanFromCheckbox(
						formProps.model.show_screening_results,
						'show_screening_results',
					),
				},
			];
			const newHealthConfig = {
				hide_all_referrals: healthConfigSubmitData.hide_all_referrals,
				survey_configs: newSurveyConfig,
			};
			try {
				await submitExistingHealthConfigForm(newHealthConfig, partnerId);
				formProps.resetAllDirtyStates();
			} catch (error) {
				//...
			}
		},
		[partnerId, getHealthConfigSubmitData, submitExistingHealthConfigForm, formProps],
	);

	return (
		<form onSubmit={onSubmit}>
			<ScreeningCard>
				<CellTitle>Mental Health Screening</CellTitle>
				<>
					<Checkbox
						{...formProps.bindWithErrorProps(
							'show_self_harm_question',
							'checkbox',
							'show_self_harm_question',
						)}
						id="show_self_harm_question"
						disabled={
							!hasValidPermissions('mental_health_screening_show_self_harm_question', [actions.UPDATE])
						}
					>
						Show PHQ-9 question on self-harm
					</Checkbox>
					<CheckboxDetails>
						”Thoughts that you would be better off dead, or of hurting yourself”
					</CheckboxDetails>
				</>
				<>
					<Checkbox
						{...formProps.bindWithErrorProps('show_screening_results', 'checkbox', 'show_screening_results')}
						id="show_screening_results"
						disabled={
							!hasValidPermissions('mental_health_screening_show_screening_results', [actions.UPDATE])
						}
					>
						Show screening results
					</Checkbox>
					<CheckboxDetails>
						Your members will receive their results after completing the mental health screening.
					</CheckboxDetails>
				</>
				<>
					<Checkbox
						{...formProps.bindWithErrorProps('all_questions_required', 'checkbox', 'all_questions_required')}
						id="all_questions_required"
						disabled={
							!hasValidPermissions('mental_health_screening_all_questions_required', [actions.UPDATE])
						}
					>
						Require all screening questions to be completed
					</Checkbox>
					<CheckboxDetails>
						Users will not be able to skip questions in order to complete the mental health screening.
					</CheckboxDetails>
				</>
			</ScreeningCard>
			<ScreeningCard>
				<CellTitle>Recommendations</CellTitle>
				<>
					<Checkbox
						{...healthConfigForm.baseFormProps.bindWithErrorProps(
							'show_referrals',
							'checkbox',
							'show_referrals',
						)}
						disabled={!hasValidPermissions('mental_health_screening_show_referrals', [actions.UPDATE])}
					>
						Show recommendations
					</Checkbox>
					<CheckboxDetails>
						Your members will receive recommendations based on user inputted data.
					</CheckboxDetails>
				</>
			</ScreeningCard>
			<StickySaveButton
				isFormDirty={hasChangedAny || healthConfigForm.hasChangedAny}
				hasTouchedForm={hasTouchedAny || healthConfigForm.hasTouchedAny}
				isLoading={loading}
				dataTestId="create-edit-partner-save-btn"
			>
				Save Configuration
			</StickySaveButton>
		</form>
	);
}

const MentalHealthScreening = ({ partner }: { partner: Partner }): React.ReactElement => {
	const { data: healthConfig, loading } = useHealthConfig({ partnerId: partner.id });
	if (healthConfig) {
		return <MentalHealthScreeningInner healthConfig={healthConfig} partnerId={partner.id} />;
	}
	if (loading) {
		return <Loader />;
	}
	return <Text>Sorry, there was an issue loading your configuration</Text>;
};
export default MentalHealthScreening;
