import { DateTime } from 'luxon';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import useForm, {
	booleanFromCheckbox,
	intFromModelValue,
	ModelValue,
	stringFromModelValue,
	validation,
	floatFromModelValue,
	FormProps,
	IncompleteDeclaration,
} from '@calm-web/use-form';

import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePermissions } from '@/hooks/auth';
import { InputFields } from '@/hooks/forms/useInputs';
import { setBannerMessage } from '@/store/actions';
import {
	Partner,
	IntegrationType,
	PartnerCategoryType,
	TrialDuration,
	StripeCouponDuration,
	PartnerPayload,
} from '@/types/store/reducers';
import {
	Duration,
	ProductSKUType,
	RenewalType,
	SHOULD_RENEW,
	SKU,
	SubscriptionModel,
} from '@/utils/SkuEnums';
import { getInitialSkuProps, getSku, isCalmHealthSKU, isSelfServePlan } from '@/utils/SkuUtils';
import { UserIdType } from '@/utils/UserIdType';
import { iso8601Date } from '@/utils/helpers';
import * as PartnerUtils from '@/utils/partner';

interface GetVouchedPlanSkuResponse {
	vouchedPlanSku: SKU | undefined;
	partnerIsOnUnchangeableSku: boolean;
}

export const ACCESS_CODE_DESCRIPTOR = 'access code';

interface UsePartnerSubmitDataResponse {
	getPartnerSubmitData: () => PartnerPayload;
	showValidationErrors: () => boolean;
	partnerIsOnUnchangeableSku: boolean;
	vouchedPlanSku?: SKU;
}

function anyProtocolUrlValidator(value: ModelValue | undefined): boolean {
	if (!value) {
		return true;
	}
	if (typeof value !== 'string') {
		return false;
	}
	try {
		// the constructor's ability to construct a value determines URL validity
		const test = new URL(value);
		return Boolean(test);
	} catch (e) {
		return false;
	}
}

function getUserIdDescriptor(
	userIdDescriptor: string,
	eligibilityType: UserIdType | undefined,
	integrationType: IntegrationType,
): string | undefined {
	if (integrationType === IntegrationType.ACCESS_CODES) {
		return ACCESS_CODE_DESCRIPTOR;
	}
	if (integrationType === IntegrationType.GROUP_CODE) {
		return 'group code';
	}
	if (eligibilityType === UserIdType.Email) {
		return 'Email';
	}
	if (!userIdDescriptor) {
		return 'other';
	}
	return userIdDescriptor;
}

const EMPLOYEE_SUBSCRIPTION_RENEWAL_FLAG = 'b2b-employee-subscription-renewal';

function getVouchedPlanSku(formProps: EditPartnerFormProps, partner?: Partner): GetVouchedPlanSkuResponse {
	const partnerIsOnUnchangeableSku = isSelfServePlan(partner?.vouched_plan_sku);
	const isLifetimeSub = formProps.model.duration === 'lifetime';

	const vouchedPlanSku = partnerIsOnUnchangeableSku
		? partner?.vouched_plan_sku
		: getSku({
				subscriptionAccessModel: formProps.model.subscriptionAccessModel as SubscriptionModel,
				shouldRenew: isLifetimeSub ? false : booleanFromCheckbox(formProps.model.shouldRenew, SHOULD_RENEW),
				duration: formProps.model.duration as Duration,
				renewalType: formProps.model.renewalType as RenewalType,
				productSKUType: formProps.model.productSKUType as ProductSKUType,
		  });

	return { vouchedPlanSku, partnerIsOnUnchangeableSku };
}

// TODO: This is nasty...see if there's much option to clean it up
export const usePartnerSubmitData = (
	formProps: EditPartnerFormProps,
	partner?: Partner,
): UsePartnerSubmitDataResponse => {
	const dispatch = useDispatch();
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = partner?.id ? [actions.UPDATE] : [actions.CREATE];
	const shouldShowIdentifier = hasValidPermissions('user_id_descriptor', requiredPermissions);

	const authorizedForRenewUrls =
		hasValidPermissions('web_renew_url', [actions.UPDATE]) &&
		hasValidPermissions('ios_renew_url', [actions.UPDATE]) &&
		hasValidPermissions('android_renew_url', [actions.UPDATE]);

	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(EMPLOYEE_SUBSCRIPTION_RENEWAL_FLAG);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const shouldShowRenewUrls = !!(
		partner?.id &&
		authorizedForRenewUrls &&
		flagsLoaded &&
		flagValues &&
		flagValues[EMPLOYEE_SUBSCRIPTION_RENEWAL_FLAG] === true
	);

	const { vouchedPlanSku, partnerIsOnUnchangeableSku } = getVouchedPlanSku(formProps, partner);
	const isCalmHealth = isCalmHealthSKU(vouchedPlanSku);

	const integrationFields = (() => {
		// For now, Calm Health partners don't have access to the same integration types and settings
		if (isCalmHealth) {
			return { vouched_plan_sku: vouchedPlanSku };
		}
		const integrationType = formProps.model.integrationType as IntegrationType;
		const isMidSsoTransition = integrationType === IntegrationType.SSO && partner?.supports_eligibility_list;
		const integrationTypeFields = {
			// When mid-SSO-transition, don't send any SKU changes until the transition is finalized
			vouched_plan_sku: isMidSsoTransition ? undefined : vouchedPlanSku,
			integration_type: integrationType,
		};
		const userIdDescriptor = stringFromModelValue(formProps.model.userIdDescriptor) ?? '';
		const eligibilityType = stringFromModelValue(formProps.model.eligibilityType) as UserIdType;

		const willSupportEligibilityList =
			integrationType === IntegrationType.ELIGIBILITY_FILE ||
			integrationType === IntegrationType.ACCESS_CODES;
		const userIdFields = shouldShowIdentifier &&
			(willSupportEligibilityList || partner?.supports_eligibility_list) && {
				user_id_descriptor: getUserIdDescriptor(userIdDescriptor, eligibilityType, integrationType),
			};
		const supportsEligibilityListFields = !partner?.id && {
			supports_eligibility_list: willSupportEligibilityList,
		};

		const groupCodeFields = integrationType === IntegrationType.GROUP_CODE && {
			group_code: stringFromModelValue(formProps.model.groupCode),
			supports_eligibility_list: willSupportEligibilityList,
		};

		const renewUrlFields = shouldShowRenewUrls && {
			web_renew_url: stringFromModelValue(formProps.model?.webRenewUrl),
			ios_renew_url: stringFromModelValue(formProps.model?.iosRenewUrl),
			android_renew_url: stringFromModelValue(formProps.model?.androidRenewUrl),
		};
		return {
			...userIdFields,
			...renewUrlFields,
			...integrationTypeFields,
			...supportsEligibilityListFields,
			...groupCodeFields,
		};
	})();

	const showEmails =
		partner?.category !== PartnerCategoryType.SALES_TRIAL &&
		stringFromModelValue(formProps.model.category) !== PartnerCategoryType.SALES_TRIAL &&
		!isCalmHealth;
	const emailFields = showEmails
		? {
				email_config_can_send_benefit_reminders: booleanFromCheckbox(
					formProps.model.emailConfig,
					'email_config_can_send_benefit_reminders',
				),
				email_config_can_send_survey: booleanFromCheckbox(
					formProps.model.emailConfig,
					'email_config_can_send_survey',
				),
		  }
		: {
				email_config_can_send_benefit_reminders: false,
				email_config_can_send_survey: false,
		  };

	const leadGenPartnershipOptions: {
		percent_off?: number | null;
		trial_duration?: TrialDuration | null;
		terms?: string | null;
		duration_type?: StripeCouponDuration;
		duration_in_months?: number;
		max_redemptions?: number;
		promotion_active?: boolean;
	} =
		formProps.model.category === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP
			? {
					percent_off: floatFromModelValue(formProps.model.percentOff) as number,
					trial_duration: stringFromModelValue(formProps.model.trialDuration) as TrialDuration,
					terms: stringFromModelValue(formProps.model.partnerSpecificTerms),
					duration_in_months: intFromModelValue(formProps.model.duration_in_months),
					duration_type: formProps.model.duration_type as StripeCouponDuration,
					max_redemptions: intFromModelValue(formProps.model.max_redemptions),
					promotion_active: booleanFromCheckbox(formProps.model.promotion_active, 'promotion_active'),
			  }
			: {};

	// Stripe does not allow us to have a 0% coupon - setting the percent_off property from the options above to null if 0
	if (leadGenPartnershipOptions?.percent_off === 0) {
		leadGenPartnershipOptions.percent_off = null;
	}

	if (leadGenPartnershipOptions?.trial_duration === 'null') {
		leadGenPartnershipOptions.trial_duration = null;
	}
	const b2bAccountId = stringFromModelValue(formProps.model.b2bAccountId);

	const complianceFields = isCalmHealth
		? { is_hipaa_compliant: true, is_ldu: true }
		: {
				is_hipaa_compliant: formProps.model.isHipaaCompliant === true.toString(),
				is_ldu: formProps.model.isLdu === true.toString(),
		  };

	const parentFields =
		(partner?.parent?.id ?? '') !== formProps.model.parentId
			? {
					parent: { id: stringFromModelValue(formProps.model.parentId) || null },
			  }
			: undefined;

	function getPartnerSubmitData(): PartnerPayload {
		const basePartnerData: PartnerPayload = {
			name: stringFromModelValue(formProps.model.partnerLongName),
			slug: stringFromModelValue(formProps.model.partnerSlug),
			...complianceFields,
			is_sales_trial: formProps.model.isSalesTrial === true.toString(),
			logo_url: stringFromModelValue(formProps.model.logoUrl),
			dtc_logo_url: stringFromModelValue(formProps.model.dtcLogoUrl) || '',
			contract_starts_at: stringFromModelValue(formProps.model.contractStartDate),
			contract_expires_at: stringFromModelValue(formProps.model.contractEndDate),
			contract_covered_lives: intFromModelValue(formProps.model.numCoveredLives),
			max_dependents_per_user: booleanFromCheckbox(formProps.model.dependentsEnabled, 'dependentsEnabled')
				? 5
				: 0,
			...integrationFields,
			...emailFields,
			category: formProps.model.category as PartnerCategoryType,
			...leadGenPartnershipOptions,
			has_churned: booleanFromCheckbox(formProps.model.hasChurned, 'churned'),
			b2b_account_id: b2bAccountId,
			faq_link: stringFromModelValue(formProps.model.faqLink) as string,
			msft_teams_tenant_id: stringFromModelValue(formProps.model.tenantId),
			...parentFields,
		};

		Object.keys(basePartnerData).forEach((partnerField: string) => {
			if (!hasValidPermissions(partnerField, requiredPermissions)) {
				delete basePartnerData[partnerField as keyof Partner];
			}
		});

		return basePartnerData;
	}

	function showValidationErrors(): boolean {
		if (formProps.validation.isValid) {
			return false;
		}
		const errorMessage =
			stringFromModelValue(
				Object.keys(formProps.validation.fields)
					.map(fieldName => {
						const typedFieldName = fieldName as keyof typeof formProps.validation.fields;
						return stringFromModelValue(formProps.validation.fields[typedFieldName]?.errors);
					})
					.filter(Boolean) as string[],
			) ?? 'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}

	return { getPartnerSubmitData, showValidationErrors, vouchedPlanSku, partnerIsOnUnchangeableSku };
};

interface UsePartnerFormResponse {
	formProps: EditPartnerFormProps;
	isPartnerFormDirty: boolean;
	hasTouchedPartnerForm: boolean;
}

export const getInitialModelForPartner = (
	partner?: Partner,
): IncompleteDeclaration<FieldNames>['initialModel'] => {
	const { subscriptionAccessModel, shouldRenew, renewalType, duration, productSKUType } = getInitialSkuProps({
		vouched_plan_sku: partner?.vouched_plan_sku ?? undefined,
	});

	function getEmailDefaults(value: string | number | boolean | undefined, defaultValue: boolean): boolean {
		return value === null || typeof value === 'undefined' ? defaultValue : Boolean(value);
	}

	const updatedEmailPermissionDefaults = {
		email_config_can_send_benefit_reminders: getEmailDefaults(
			partner?.email_config_can_send_benefit_reminders,
			false,
		),
	};

	const legacyEmailPermissionDefaults = {
		email_config_can_send_promo: getEmailDefaults(partner?.email_config_can_send_promo, true),
		email_config_can_send_upsell: getEmailDefaults(partner?.email_config_can_send_upsell, false),
		email_config_can_send_download_app: getEmailDefaults(partner?.email_config_can_send_download_app, true),
		email_config_is_branded: getEmailDefaults(partner?.email_config_is_branded, false),
	};

	const initialEligibilityType =
		!partner?.user_id_descriptor || PartnerUtils.usesEmailAsDescriptor(partner)
			? UserIdType.Email
			: UserIdType.Custom;

	return {
		partnerLongName: partner?.name ?? '',
		partnerSlug: partner?.slug ?? '',
		eligibilityType: initialEligibilityType,
		logoUrl: partner?.logo_url ?? '',
		uploadedLogo: [],
		dtcLogoUrl: partner?.dtc_logo_url ?? '',
		uploadedDtcLogo: [],
		userIdDescriptor: partner?.user_id_descriptor ?? '',
		integrationType: partner?.integration_type || IntegrationType.ELIGIBILITY_FILE,
		contractStartDate: partner?.contract_starts_at ?? iso8601Date(new Date()),
		contractEndDate:
			partner?.contract_expires_at ?? iso8601Date(DateTime.now().plus({ years: 1 }).toJSDate()),
		numCoveredLives: partner?.contract_covered_lives?.toString() ?? '',
		dependentsEnabled: (partner?.max_dependents_per_user ?? 0) > 0 ? ['dependentsEnabled'] : [],
		webRenewUrl: partner?.web_renew_url ?? '',
		iosRenewUrl: partner?.ios_renew_url ?? '',
		androidRenewUrl: partner?.android_renew_url ?? '',
		isHipaaCompliant: Boolean(partner?.is_hipaa_compliant).toString(),
		isLdu: Boolean(partner?.is_ldu).toString(),
		isSalesTrial: Boolean(partner?.is_sales_trial).toString(),
		duration,
		subscriptionAccessModel,
		shouldRenew: shouldRenew ? [SHOULD_RENEW] : [],
		renewalType,
		category: partner?.category || PartnerCategoryType.CALM_FOR_BUSINESS,
		percentOff: (partner?.offer_details?.percent_off ?? 0).toString(),
		trialDuration: partner?.offer_details?.trial_duration || TrialDuration.NULL,
		duration_type: partner?.offer_details?.duration_type || StripeCouponDuration.ONCE,
		duration_in_months: partner?.offer_details?.duration_in_months?.toString() || '',
		max_redemptions: partner?.offer_details?.max_redemptions?.toString() || '',
		promotion_active: partner?.offer_details?.promotion_active !== false ? ['promotion_active'] : [],
		partnerSpecificTerms: partner?.offer_details?.terms || '',
		faqLink: partner?.faq_link || '',
		productSKUType,
		hasChurned: partner?.has_churned ? ['churned'] : [],
		emailConfig: Object.entries({
			...updatedEmailPermissionDefaults,
			...legacyEmailPermissionDefaults,
			email_config_can_send_survey: getEmailDefaults(partner?.email_config_can_send_survey, true),
		})
			.filter(([, value]) => Boolean(value))
			.map(([configKey]) => configKey),
		b2bAccountId: partner?.b2b_account_id ?? '',
		groupCode: partner?.group_code ?? '',
		tenantId: partner?.msft_teams_tenant_id ?? '',
		parentId: partner?.parent?.id ?? '',
	};
};

export const usePartnerForm = (partner?: Partner): UsePartnerFormResponse => {
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = partner?.id ? [actions.UPDATE] : [actions.CREATE];

	const shouldShowIdentifier = hasValidPermissions('user_id_descriptor', requiredPermissions);
	const shouldShowPlanDetails = hasValidPermissions('contract_expires_at', [actions.READ]);

	const formProps: EditPartnerFormProps = useForm('partnerForm', {
		initialModel: getInitialModelForPartner(partner),
		validation: {
			partnerSlug: validation.validateOrFail([
				{
					rules: [validation.required, validation.minLength(2)],
					errorResult: 'Please enter a partner slug at least 2 characters long',
				},
				{
					rules: [validation.regexMatch(/^[0-9a-z-]+$/)],
					errorResult: 'Partner slug must only contain letters, numbers, and dashes',
				},
			]),
			partnerLongName: validation.validateOrFail([
				{
					rules: [validation.required, validation.minLength(2)],
					errorResult: 'Please enter a partner name at least 2 characters long',
				},
			]),
			userIdDescriptor: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							!(
								props.model.integrationType === IntegrationType.ELIGIBILITY_FILE &&
								shouldShowIdentifier &&
								props.model.eligibilityType === UserIdType.Custom &&
								!value
							),
					],
					errorResult: 'Please specify a unique identifier for users',
				},
			]),
			contractEndDate: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							if (!shouldShowPlanDetails) {
								return true;
							}
							const contractStartDate = stringFromModelValue(props.model.contractStartDate);
							const contractEndDate = stringFromModelValue(value);
							return !(contractStartDate && contractEndDate && contractEndDate <= contractStartDate);
						},
					],
					errorResult: 'Contract end date must be later than the start date',
				},
			]),
			numCoveredLives: validation.validateOrFail([
				{
					rules: [(value: ModelValue | undefined) => !shouldShowPlanDetails || intFromModelValue(value) > 0],
					errorResult: 'Please specify the number of covered lives',
				},
			]),
			webRenewUrl: validation.validateOrFail([
				{
					rules: [validation.emptyOrValidURL],
					errorResult: 'Please enter a valid URL',
				},
			]),
			iosRenewUrl: validation.validateOrFail([
				{
					rules: [anyProtocolUrlValidator],
					errorResult: 'Please enter a valid URL',
				},
			]),
			androidRenewUrl: validation.validateOrFail([
				{
					rules: [anyProtocolUrlValidator],
					errorResult: 'Please enter a valid URL',
				},
			]),
			percentOff: validation.validateOrFail([
				{
					rules: [
						(value: ModelValue | undefined) =>
							intFromModelValue(value) >= 0 && intFromModelValue(value) <= 100,
					],
					errorResult: 'Please select a value between 0 and 100',
				},
			]),
			groupCode: validation.validateOrFail([
				{
					rules: [(value, props) => !(props.model.integrationType === IntegrationType.GROUP_CODE && !value)],
					errorResult: 'Please enter a group code',
				},
				{
					rules: [
						(value, props) =>
							!(
								props.model.integrationType === IntegrationType.GROUP_CODE &&
								!validation.and(validation.minLength(4), validation.maxLength(20))(value, props)
							),
					],
					errorResult: 'Group code must be between 4 and 20 characters',
				},
				{
					rules: [
						(value, props) =>
							!(
								props.model.integrationType === IntegrationType.GROUP_CODE &&
								!validation.regexMatch(/^[0-9a-zA-Z-]+$/)(value, props)
							),
					],
					errorResult: 'Group code must only contain letters, numbers, and dashes',
				},
			]),
			tenantId: validation.validateOrFail([
				{
					rules: [
						(value, props) =>
							!(value && value.length > 0 && !validation.regexMatch(/^[0-9a-zA-Z-]+$/)(value, props)),
					],
					errorResult: 'Please enter a valid tenant ID',
				},
			]),
		},
	});

	const isPartnerFormDirty = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedPartnerForm = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, isPartnerFormDirty, hasTouchedPartnerForm };
};

export const useManualSurveyForm = (): {
	formProps: ManualDateFormProps;
	isPartnerFormDirty: boolean;
	hasTouchedPartnerForm: boolean;
} => {
	const formProps: ManualDateFormProps = useForm('manualSurveyForm', {
		initialModel: {
			manualSurveyDate: '',
		},
	});
	const isPartnerFormDirty = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedPartnerForm = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);
	return { formProps, isPartnerFormDirty, hasTouchedPartnerForm };
};

interface InputActions {
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	onFocus: () => void;
	onClear: () => void;
}

export interface InputTypes {
	inputFields: InputFields;
	inputActions: InputActions;
	isEdit?: boolean;
	isReadOnly?: boolean;
}

export type ManualDateFormProps = FormProps<'manualSurveyDate'>;

export type FieldNames =
	| 'partnerLongName'
	| 'partnerSlug'
	| 'eligibilityType'
	| 'logoUrl'
	| 'uploadedLogo'
	| 'dtcLogoUrl'
	| 'uploadedDtcLogo'
	| 'userIdDescriptor'
	| 'integrationType'
	| 'contractStartDate'
	| 'contractEndDate'
	| 'numCoveredLives'
	| 'dependentsEnabled'
	| 'webRenewUrl'
	| 'iosRenewUrl'
	| 'androidRenewUrl'
	| 'isSalesTrial'
	| 'isHipaaCompliant'
	| 'isLdu'
	| 'duration'
	| 'emailConfig'
	| 'subscriptionAccessModel'
	| 'shouldRenew'
	| 'renewalType'
	| 'category'
	| 'percentOff'
	| 'trialDuration'
	| 'duration_type'
	| 'duration_in_months'
	| 'productSKUType'
	| 'hasChurned'
	| 'b2bAccountId'
	| 'partnerSpecificTerms'
	| 'faqLink'
	| 'groupCode'
	| 'tenantId'
	| 'parentId'
	| 'max_redemptions'
	| 'promotion_active';

export type EditPartnerFormProps = FormProps<FieldNames>;
