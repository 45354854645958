import axios from 'axios';
import { useCallback, useState } from 'react';

import { useApi } from '@/hooks/api';
import { getCalmErrorOrError } from '@/utils/apiRequest/errors';

export function useUploadSftpEligibilityFile(partnerId: string): {
	upload: (file: File) => Promise<void>;
	progress: number;
	isUploading: boolean;
} {
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const apiRequest = useApi();
	const upload = useCallback(
		async (file: File) => {
			try {
				setIsUploading(true);
				setProgress(0);

				// get signed url
				const response = await apiRequest({
					endpoint: `b2b/partners/${partnerId}/eligibility-upload/signed-url?file_name=${file.name}`,
				});
				const signedUrl = response?.data?.upload_url;

				if (!signedUrl) {
					throw new Error('SIGNED_URL_NOT_FOUND');
				}

				// upload the file
				await axios.put(signedUrl, file, {
					onUploadProgress: progressEvent => {
						const total = progressEvent.total ?? 1; // Default to 1 to avoid division by zero
						const progress = Math.round((progressEvent.loaded * 100) / total);
						setProgress(progress);
					},
				});
			} catch (error) {
				throw getCalmErrorOrError(error);
			} finally {
				setIsUploading(false);
			}
		},
		[apiRequest, partnerId],
	);
	return { upload, progress, isUploading: isUploading };
}
