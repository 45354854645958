import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Tooltip, Text, FontSizes } from '@calm-web/design-system';

import { useEligibilityUploads } from '@/hooks/api/useEligibility';
import { PreviousUploadResults } from '@/types/eligibility';

import messages from './messages';
import { InfoIcon } from './styles';

interface LastUploadInfoProps {
	partnerId: string;
}

interface LastUploadTooltipProps {
	usersAdded?: number;
	usersDeleted?: number;
	segmentsAdded?: number;
	segmentsDeleted?: number;
	segmentsUpdated?: number;
}
const emptyUpload: PreviousUploadResults = {
	id: '',
	delivery_mechanism: '',
	users_added: 0,
	users_deleted: 0,
	segments_added: 0,
	segments_deleted: 0,
	segments_updated: 0,
	processing_start_at: '',
	processing_end_at: '',
	okta_user_email: '',
	users_duplicated: null,
	users_total: null,
	name: '',
	status: 'completed',
	error: null,
	md5: null,
};

const TooltipContent: FC<LastUploadTooltipProps> = ({
	usersAdded,
	usersDeleted,
	segmentsAdded,
	segmentsDeleted,
	segmentsUpdated,
}) => {
	const { formatMessage } = useIntl();

	return (
		<>
			{usersAdded ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadUsersAdded, {
						usersAdded,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{usersDeleted ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadUsersDeleted, {
						usersDeleted,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsAdded ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsAdded, {
						segmentsAdded,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsDeleted ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsDeleted, {
						segmentsDeleted,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsUpdated ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsUpdated, {
						segmentsUpdated,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
		</>
	);
};

const LastUploadInfo: FC<LastUploadInfoProps> = ({ partnerId }) => {
	const { loading, data: eligibilityUploadData } = useEligibilityUploads({
		partnerId,
		status: 'completed',
		pageIndex: 0,
		pageSize: 1,
	});

	const { formatMessage } = useIntl();
	const uploads = eligibilityUploadData?.eligibility_uploads;
	const latestUpload = uploads?.length ? uploads[0] : emptyUpload;
	const {
		delivery_mechanism,
		processing_start_at,
		users_added,
		users_deleted,
		segments_added,
		segments_deleted,
		segments_updated,
	} = latestUpload;

	const canShowStats =
		Boolean(users_added) ||
		Boolean(users_deleted) ||
		Boolean(segments_added) ||
		Boolean(segments_deleted) ||
		Boolean(segments_updated);

	if (loading) return null;
	if (!canShowStats) {
		return (
			<Text el="p" size={FontSizes.sm} color="gray7">
				{formatMessage(messages.noPreviousSyncHeader)}
			</Text>
		);
	}

	const isEf = delivery_mechanism === 'partner_portal';

	if (isEf) {
		return (
			<Text el="p" size={FontSizes.sm} color="gray7">
				{formatMessage(messages.lastUploadHeader, {
					date: new Date(processing_start_at || '').toLocaleString().toLowerCase(),
				})}
				<Tooltip
					content={
						<TooltipContent
							usersAdded={users_added ?? undefined}
							usersDeleted={users_deleted ?? undefined}
							segmentsAdded={segments_added ?? undefined}
							segmentsDeleted={segments_deleted ?? undefined}
							segmentsUpdated={segments_updated ?? undefined}
						/>
					}
				>
					<InfoIcon />
				</Tooltip>
			</Text>
		);
	}

	return (
		<Text el="p" size={FontSizes.sm} color="gray7">
			{formatMessage(messages.lastSyncHeader, {
				date: new Date(processing_start_at || '').toLocaleString().toLowerCase(),
			})}
		</Text>
	);
};

export default LastUploadInfo;
